import React, { useContext, useState } from 'react';
import SubscriptionContext from "../contexts/SubscriptionContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Box, IconButton, Link, Typography, Button } from '@mui/material';
import { MapIcon, GlobeAltIcon, BookmarkIcon, QuestionMarkCircleIcon, LockClosedIcon, WalletIcon, Bars3Icon, XMarkIcon, ArrowLeftEndOnRectangleIcon, SparklesIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import Logo from '../theme/logo.svg';
import IconParkDubai from './icons/dubai';
import MynauiSearchWaves from './icons/market';

import {
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";

const NewMenu = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { user: dbUser } = useContext(SubscriptionContext);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <>
      <Box sx={{
        position: 'fixed',
        top: 0,
        zIndex: { xs: 1001, sm: 1001 },
        height: { xs: '57px', sm: '65px' },
        width: menuOpen ? '100%' : '65px',
        borderBottom: '1px solid #e9e9e9',
        borderRight: '1px solid #e9e9e9',
        display: { xs: 'flex', sm: 'none' },
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        marginBottom: '30px',
      }}>
        {menuOpen ? (
          <XMarkIcon onClick={() => {
            handleMenuOpen()
          }} strokeWidth="1.5" style={{ height: 20, margin: 10 }} />
        ) : (
          <Bars3Icon onClick={() => {
            handleMenuOpen()
          }} strokeWidth="1.5" style={{ height: 20, margin: 10 }} />
        )}
      </Box>

      <Box sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        display: { xs: menuOpen ? 'flex' : 'none', sm: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        marginTop: { xs: '57px', sm: '0' },
        paddingTop: { xs: '57px', sm: '0' },
        width: { xs: '100%', sm: '95px' },
        zIndex: { xs: 1001, sm: 1001 },
        backgroundColor: '#fff',
        borderRight: '1px solid #e9e9e9',
      }}>
        <Box sx={{
          display: { xs: 'none', sm: 'flex' },
          height: { xs: '57px', sm: '65px' },
          width: '95px',
          borderBottom: '1px solid #e9e9e9',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '30px',
        }}>
          <a href="https://mappi.ae">
            <img src={Logo} alt="React Logo" height={40} />
          </a>
        </Box>

        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/`);
          }} sx={{
            mb: '20px',
            color: '#000',
            // "&:hover": {
            //   color: 'rgb(87,54,238)',
            // },
          }}>
          <Badge color="secondary" variant="dot" invisible={location.pathname !== '/' && !location.pathname.includes('/district', '/project')}>
            <MapIcon strokeWidth="1.5" style={{ width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>Explore map</Typography>
        </IconButton>


        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/dubai`);
          }} sx={{
            mb: '20px',
            color: '#000',
            "&:hover": {
              color: 'rgb(87,54,238)',
            },
          }}>
          <Badge color="secondary" variant="dot" invisible={!location.pathname.includes('/dubai')}>
            <IconParkDubai strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>Dubai Statistics</Typography>
        </IconButton>

        {/* {dbUser?.isBeta && ( */}
          <IconButton aria-label="close" size="meddium" component="label"
            onClick={() => {
              navigate(`/specials`);
            }} sx={{
              mb: '20px',
              color: '#000',
              "&:hover": {
                color: 'rgb(87,54,238)',
              },
            }}>
            <Badge color="secondary" variant="dot" invisible={!['/specials', '/special'].some(fruit => location.pathname.includes(fruit))}>
              <SparklesIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
            </Badge>
            <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>Special offers</Typography>
          </IconButton>
        {/* )} */}

          <IconButton aria-label="close" size="meddium" component="label"
            onClick={() => {
              navigate(`/offmarket`);
            }} sx={{
              mb: '20px',
              color: '#000',
              "&:hover": {
                color: 'rgb(87,54,238)',
              },
            }}>
            <Badge color="secondary" variant="dot" invisible={!['/offmarket', '/offmarket'].some(fruit => location.pathname.includes(fruit))}>
              <UserGroupIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
            </Badge>
            <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>Off-market</Typography>
          </IconButton>

        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/market-data`);
          }} sx={{
            mb: '20px',
            color: '#000',
            "&:hover": {
              color: 'rgb(87,54,238)',
            },
          }}>
          <Badge color="secondary" variant="dot" invisible={!['/market-data'].some(fruit => location.pathname.includes(fruit))}>
            <MynauiSearchWaves strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>Market data</Typography>
        </IconButton>

        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/favourites`);
          }} sx={{
            mb: '20px',
            color: '#000',
            "&:hover": {
              color: 'rgb(87,54,238)',
            },
          }}>
          <Badge color="secondary" variant="dot" invisible={!location.pathname.includes('/favourites')}>
            <BookmarkIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>My favourites</Typography>
        </IconButton>

        <Box sx={{
          width: '100%',
          borderBottom: '1px solid #e9e9e9',
          mb: '20px',
        }}></Box>

        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/wallet`);
          }} sx={{
            mb: '20px',
            color: '#000',
            "&:hover": {
              color: 'rgb(87,54,238)',
            },
          }}>
          <Badge color="secondary" variant="dot" invisible={!location.pathname.includes('/wallet')}>
            <WalletIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>My Wallet</Typography>
        </IconButton>

        <IconButton aria-label="close" size="meddium" component="label"
          onClick={() => {
            navigate(`/about`);
          }} sx={{
            mb: '20px',
            color: '#000',
            "&:hover": {
              color: 'rgb(87,54,238)',
            },
          }}>
          <Badge color="secondary" variant="dot" invisible={!location.pathname.includes('/about')}>
            <QuestionMarkCircleIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </Badge>
          <Typography variant="caption" sx={{ display: { xs: 'block', sm: 'none' }, fontWeight: 'bold' }}>About</Typography>
        </IconButton>

        {dbUser ? (
          <IconButton aria-label="close" size="meddium"
            onClick={() => {
              signOut(auth);
              navigate('/');
            }} sx={{
              position: 'inherit',
              bottom: 0,
              mb: '20px',
              color: '#000',
              "&:hover": {
                color: 'rgb(87,54,238)',
              },
            }}>
            <LockClosedIcon strokeWidth="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </IconButton>
        ) : (
          <IconButton aria-label="close" size="meddium"
            onClick={() => {
              navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`);
            }} sx={{
              position: 'inherit',
              bottom: 0,
              mb: '20px',
              color: '#000',
              "&:hover": {
                color: 'rgb(87,54,238)',
              },
            }}>
            <ArrowLeftEndOnRectangleIcon stroke-width="1.5" style={{ color: '#000', width: 25, margin: '5px' }} />
          </IconButton>
        )}
      </Box>
    </>
  )
};

export default NewMenu;