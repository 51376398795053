import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player/youtube'
import API from '../controllers/api.js';

const styleContacts = {
  width: 500,
  p: 4,
};

const About = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [isSending, setIsSending] = useState(false);
  const [sentOK, setSentOK] = useState(false);
  const [sendFail, setSendFail] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const onSubmit = async data => {
    setIsSending(true)
    if (errors.length > 0) {
      setIsSending(false)
      setSendFail(true)
      return
    }
    await API.post('/contactus', data)
    setSendFail(false)
    setSentOK(true)
    setIsSending(false)
  }


  useEffect(() => {
  }, []);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: '100px' }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {t('support.title')}
                </Typography>
                <Typography variant="subtitle1"> {t('support.subTitle')}</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            <Grid2 xs={12} md={12} pb={3}>
              <Typography variant="h1" sx={{
                pb: 3,
              }}>
                {t('support.content.h1')}
              </Typography>
              <Typography variant="content" sx={{
                pb: 3,
              }}>
                {t('support.content.c1')}
              </Typography>
              <Typography variant="content" sx={{
                pb: 3,
              }}>
                {t('support.content.c12')}{"\n"}
              </Typography>
              <Typography variant="h1" sx={{
                pb: 3,
              }}>
                {t('support.content.h3')}
              </Typography>
              {[1, 2, 3, 4, 5, 6, 7, 8].map(element => (
                <>
                  <Typography variant="h4" sx={{
                    fontWeight: 600,
                    color: '#000',
                    pb: 3,
                  }}>
                    {t(`support.content.q${element}`)}
                  </Typography>
                  <Typography variant="content" sx={{
                    pb: 3,
                  }}>
                    {t(`support.content.a${element}`)}
                  </Typography>
                </>
              ))}
              
              <Typography variant="h1" sx={{
                pb: 3,
              }}>
                {t('support.content.h2')}
              </Typography>
              <Typography variant="content" sx={{
                pb: 3,
              }}>
                {t('support.content.c2')}{"\n"}
              </Typography>
              <Box sx={{
                padding: 5,
                display: 'flex',
                justifyContent: 'center',
              }}>
                <ReactPlayer
                  controls={true}
                  url='https://youtu.be/5fGFfEHwDck'
                />
              </Box>
            </Grid2>


            <Grid2 container xs={12} md={12} p={0} sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
              <Box sx={styleContacts}>
              <Typography variant="h1" sx={{
                pb: 3,
              }}>
                {t('support.contactUs')}
              </Typography>
              <Typography variant="content" sx={{
                pb: 3,
              }}>
                {t('support.contactDesc')}
              </Typography>
                <Grid2 container spacing={2} pt={1} >
                  {sentOK ? (
                    <Alert severity="success">
                      <Typography variant="h3">{t('general.topNav.support.success')}</Typography>
                    </Alert>
                  ) : null
                  }
                  {sendFail ? (
                    <Alert severity="error">
                      <Typography variant="h3">{t('general.topNav.support.fail')}</Typography>
                    </Alert>
                  ) : null
                  }
                  <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      defaultValue={dbUser?.name}
                      fullWidth
                      placeholder={t('general.topNav.support.name')}
                      type="text"
                      helperText={errors.name && (errors.name.message || 'This field is required')}
                      {...register("name", { required: true, maxLength: 80 })} />
                    <TextField
                      type="email"
                      margin="normal"
                      defaultValue={dbUser?.email}
                      fullWidth
                      placeholder={t('general.topNav.support.email')}
                      helperText={errors.email && (errors.email.message || 'This field is required')}
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                    <TextField
                      fullWidth
                      multiline
                      placeholder={t('general.topNav.support.message')}
                      margin="normal"
                      helperText={errors.message && (errors.message.message || 'This field is required')}
                      rows={4}
                      {...register("message", { required: true, max: 1200, min: 10 })} />

                    <Button
                      fullWidth
                      disabled={isSending || sentOK}
                      size="large"
                      color="primary"
                      variant="contained"
                      type="submit"
                    // endIcon={<SendIcon />}
                    >
                      {t('general.topNav.support.button')}
                    </Button>
                  </Box>
                </Grid2>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default About;