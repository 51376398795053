import { useEffect, useState, useContext } from 'react';

import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "./contexts/AuthContext.js";
import SubscriptionContext from "./contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import { trackEvent } from './components/common/plausible';
import Typography from "@mui/material/Typography";

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
// const stripePromise = loadStripe('pk_test_51N5xtOA1UwXlBVmJuFIHEPoJ4CloUIhi9Zq9tkcKBJ7QNH9RES7ANfbcDoiTmwnTEcJtqPY5FBuwtzO5dMiJWVtK00IH5f44c3'); // test
const stripePromise = loadStripe('pk_live_51N5xtOA1UwXlBVmJL9xyN6ljx0uM269xPGbCRUVEWpDaBAHjOCVwT9eYgoR9FWnGiEuxI2FZVh8cxQcBDzjPrrJ800oYNBrRny');  // live

const Payment = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // Get the lookup key for the price from the previous page redirect.
  console.log('location', location);
  const [clientSecret, setClientSecret] = useState(location.state?.clientSecret);

  useEffect(() => {
    trackEvent('Payment')
    if (!clientSecret) {
      navigate('/checkout');
    }
  }, [clientSecret]);

  return (
    <>
      <Box>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {t('payment.title')}
                </Typography>
                <Typography variant="subtitle1">{t('payment.subTitle')}</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            <Grid2 container xs={12} md={12} p={0} pt={3} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {/* <div id="checkout"> */}
              <Box id="checkout" sx={{
                width: '100%',
              }}>
                {clientSecret && (
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                    style={{ width: '100%' }}
                  >
                    <EmbeddedCheckout style={{ width: '100%' }} />
                  </EmbeddedCheckoutProvider>
                )}
              </Box>
              {/* </div> */}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Payment;